import { Button, Classes, InputGroup, Text } from "@blueprintjs/core"
import React, { Dispatch, FormEvent, SetStateAction } from "react"
import { Recommendation } from "./Recommendation";
import './App.css';

const IP_ADDRESS = "https://spotify-recommender.herrero.dev/";

export interface IAlbum {
    album_type: string, 
    artists: {external_urls: {spotify: string}, href: string, id: string, name: string, type: string, uri: string}[], 
    available_markets: string[], 
    external_urls: {spotify: string}, 
    href: string, 
    id: string, 
    images: {height: number, url: string, width: number}[], 
    name: string, 
    release_date: string, 
    release_date_precision: string, 
    total_tracks: number, 
    type: string, 
    uri: string,
}
export interface IArtist {
    external_urls: {
        spotify: string
    },
    href: string, 
    id: string, 
    name: string, 
    type: string, 
    uri: string,
}
export interface IRecommendation {
    album: IAlbum;
    artists: IArtist[]
    available_markets: string[],
    disc_number: number,
    duration_ms: number,
    explicit: boolean,
    external_urls: {spotify: string},
    name: string,
    popularity: number,
    preview_url: string,
    track_number: number,
    type: string,
}

export interface IInputViewProps {
}

export const InputView: React.FC<IInputViewProps> = ({}) => {
    const [firstSong, setFirstSong] = React.useState<string>()
    const [secondSong, setSecondSong] = React.useState<string>()
    const [thirdSong, setThirdSong] = React.useState<string>()
    const [fourthSong, setFourthSong] = React.useState<string>()
    const [fifthSong, setFifthSong] = React.useState<string>()
    const [recommendations, setRecommendations] = React.useState<IRecommendation[]>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleSongChange = (setter: Dispatch<SetStateAction<string | undefined>>): React.FormEventHandler<HTMLElement> => {
        const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
            setter(event.currentTarget.value)
        }
        return handleChange;
    }

    const getQueryParam = () => {
        return encodeURIComponent(`${firstSong},${secondSong},${thirdSong},${fourthSong},${fifthSong}`);
    }

    const handleFindRecommendation = async () => {
        setIsLoading(true);
        const response = await fetch(`${IP_ADDRESS}api/v1/recommendations?seed_tracks=${getQueryParam()}`);
        const body: IRecommendation[] = await response.json();
        setIsLoading(false);
        setRecommendations(body)
    }

    return (
        <div className="input-view">
            <InputGroup
                placeholder="First song"
                value={firstSong}
                onChange={handleSongChange(setFirstSong)}
                className="input"
            />
            <InputGroup
                placeholder="Second song"
                value={secondSong}
                onChange={handleSongChange(setSecondSong)}
                className="input"
            />
            <InputGroup
                placeholder="Third song"
                value={thirdSong}
                onChange={handleSongChange(setThirdSong)}
                className="input"
            />
            <InputGroup
                placeholder="Fourth song"
                value={fourthSong}
                onChange={handleSongChange(setFourthSong)}
                className="input"
            />
            <InputGroup
                placeholder="Fifth song"
                value={fifthSong}
                onChange={handleSongChange(setFifthSong)}
                className="input"
            />
            <Button
                className="button"
                intent="primary"
                text={<Text className="text">Find Recommendation </Text>}
                icon="predictive-analysis"
                onClick={handleFindRecommendation}
                loading={isLoading}
            />
            {
                recommendations?.map(rec => <Recommendation recommendation={rec} />)
            }
        </div>
    )
}