import { Button, Card, Classes, InputGroup, Text } from "@blueprintjs/core"
import React, { Dispatch, FormEvent, SetStateAction } from "react"
import './App.css';
import { IRecommendation } from "./InputView";

export interface IRecommendationProps {
    recommendation: IRecommendation;
}

export const Recommendation: React.FC<IRecommendationProps> = ({recommendation}) => {
    const artists = (
        <div className="artists">
            {recommendation.artists.map((artist, index) => <Text className="text"> <a href={artist.external_urls.spotify}>{artist.name}</a>{(index+1 < recommendation.artists.length) ? ",  " : ""}</Text>)}
        </div>
    )

    const songName = (
        <Text className="text-bold"><a href={recommendation.external_urls.spotify}>{recommendation.name}</a></Text>
    )

    const albumName = (
        <Text className="text"><a href={recommendation.album.external_urls.spotify}>{recommendation.album.name}</a></Text>
    )

    const albumPhoto = (
        <img className="album-photo" src={recommendation.album.images.find(image => image.height === 64)?.url}/> 
    )

    console.log(recommendation)

    return (
        <Card className="card">
            {albumPhoto}
            <div className="split">
                {songName}
                {artists}
                {albumName}
            </div>            
        </Card>
    )
}